<template>
  <div>
    <!--数据表格-->
    <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 390px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="realname"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="airtightTypeName"
              label="试验类型"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="result"
              label="试验状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{
                  row.result==1?'实验完成':(row.airtightSubs?(row.result==3&&row.airtightSubs.isSubRectify?'实验完成':'实验中'):'未出结果')
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="试验前提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="updateTime"
              label="试验后提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="result"
              label="试验结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag :type="result[row.result] == '合格' ? 'success' : 'danger'">
                  {{ result[row.result] || "" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  v-if="row.airtightType == 2"
                  size="small"
                  @click="seeDetil(row, 2)"
                  >查看</el-button
                >
                <el-button
                  type="primary"
                  v-else
                  size="small"
                  @click="seeDetil(row, 1)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>

    <el-dialog
      title="普通气密详情"
      :visible.sync="detilDialog"
      width="40%"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="project"
        v-if="detilDialog"
        :model="project"
        label-width="120px"
        size="mini"
      >
        <div style="max-height: 600px; overflow: auto">
          <div>
            <p class="detil-code">
              试验信息
              <!-- <span :class="{ 'unqualified': currentRow.result == 3 }">不合格</span> -->
            </p>
            <div style="padding: 10px">
              <div class="item-list">试验类型： 普通气密</div>
              <div style="display: flex; flex-wrap: wrap">
                <div class="item-list" style="flex: 1">
                  <div>
                    <div class="detil-code">实验前：</div>
                    <div>
                      <el-image
                        v-if="currentRow.img"
                        style="width: 100px; height: 100px"
                        :src="currentRow.img.split(',')[0]"
                        :preview-src-list="currentRow.img.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="currentRow.img"
                        >共{{ currentRow.img.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div class="item-list" style="margin-top: 10px">
                    提交人： {{ currentRow.createBy }}
                  </div>
                  <div class="item-list">
                    提交时间： {{ currentRow.createTime }}
                  </div>
                </div>
                <div
                  class="item-list"
                  style="flex: 1"
                  v-for="(item, index) in currentRow.workTestAirtightSubList"
                  :key="index"
                >
                  <div>
                    <div class="detil-code">实验后{{ index + 1 }}：</div>
                    <div>
                      <el-image
                        v-if="item.imgLater"
                        style="width: 100px; height: 100px"
                        :src="item.imgLater.split(',')[0]"
                        :preview-src-list="item.imgLater.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="item.imgLater"
                        >共{{ item.imgLater.split(",").length }}张</span
                      >
                    </div>
                    <div class="item-list" style="margin-top: 10px">
                      气密结果：<span
                        :class="{ unqualified: item.result == 3 }"
                      >
                        {{ resulttype[item.result] }}
                        <span v-if="!item.isSubRectify && item.result == 3"
                          >不签发整改通知单</span
                        >
                        <span v-else-if="item.result == 3"
                          >签发整改通知单</span
                        ></span
                      >
                    </div>
                    <div class="item-list">提交人： {{ item.createBy }}</div>
                    <div class="item-list">
                      提交时间： {{ item.createTime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="item in rectification" :key="item.id">
            <div style="display: flex" v-if="item.img">
              <div style="flex: 1">
                <p class="detil-code">整改单</p>
                <div style="padding: 10px">
                  <div class="item-list">整改原因： {{ item.cause }}</div>
                  <div class="item-list">
                    <div style="margin-bottom: 10px">不合格照片：</div>
                    <div>
                      <el-image
                        v-if="item.img"
                        style="width: 100px; height: 100px"
                        :src="item.img.split(',')[0]"
                        :preview-src-list="item.img.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="item.img"
                        >共{{ item.img.split(",").length }}张</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1" v-if="item.teams">
                <p class="detil-code">整改处理</p>
                <div style="padding: 10px">
                  <div class="item-list">
                    处理结果： {{ state[item.state] }}
                  </div>
                  <div class="item-list">
                    施工队： {{ item.teams.join("；") }}
                  </div>
                  <div class="item-list">操作人： {{ item.updateBy }}</div>
                  <div class="item-list">操作时间： {{ item.updateTime }}</div>
                </div>
              </div>
            </div>
            <div v-if="isShow(item.workInspectionSgdSubsetList)">
              <p class="detil-code">整改情况</p>
              <div style="padding: 10px">
                <div
                  v-for="list in item.workInspectionSgdSubsetList"
                  :key="list.id"
                  style="display: flex; flex-wrap: wrap"
                >
                  <div
                    style="margin-right: 20px; margin-bottom: 20px"
                    v-if="list.laterImg"
                  >
                    <div class="item-list">
                      整改施工队： {{ list.workTeamName }}
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">整改后情况：</div>
                      <div>
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="list.laterImg.split(',')[0]"
                          :preview-src-list="list.laterImg.split(',')"
                        >
                        </el-image>
                        <span style="margin-left: 10px" v-if="list.laterImg"
                          >共{{ list.laterImg.split(",").length }}张</span
                        >
                      </div>
                    </div>
                    <div class="item-list">
                      整改说明： {{ list.explain || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="计算气密详情"
      :visible.sync="detilCalculate"
      width="40%"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="project"
        v-if="detilCalculate"
        label-width="120px"
        size="mini"
      >
        <div style="max-height: 600px; overflow: auto">
          <div>
            <p class="detil-code">试验信息</p>
            <div style="padding: 10px">
              <div class="item-list">试验类型： 计算气密</div>
              <div style="display: flex; flex-wrap: wrap">
                <div class="item-list" style="flex: 1">
                  <div class="detil-code">实验前：</div>
                  <div v-if="currentRow.isAbsolute">
                    <div class="item-list">
                      绝压表度数：{{ currentRow.jyNumber }} Pa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">绝压表照片：</div>
                      <el-image
                        v-if="currentRow.jyImg"
                        style="width: 100px; height: 100px"
                        :src="currentRow.jyImg.split(',')[0]"
                        :preview-src-list="currentRow.jyImg.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="currentRow.jyImg"
                        >共{{ currentRow.jyImg.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div v-if="!currentRow.isAbsolute">
                    <div class="item-list">
                      压力表度数：{{ currentRow.pressureNumber }} Mpa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">压力表照片：</div>
                      <el-image
                        v-if="currentRow.pressureImg"
                        style="width: 100px; height: 100px"
                        :src="currentRow.pressureImg.split(',')[0]"
                        :preview-src-list="currentRow.pressureImg.split(',')"
                      >
                      </el-image>
                      <span
                        style="margin-left: 10px"
                        v-if="currentRow.pressureImg"
                        >共{{
                          currentRow.pressureImg.split(",").length
                        }}张</span
                      >
                    </div>
                  </div>
                  <div v-if="!currentRow.isAbsolute">
                    <div class="item-list">
                      气压表度数：{{ currentRow.airNumber }} pa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">气压表照片：</div>
                      <el-image
                        v-if="currentRow.airImg"
                        style="width: 100px; height: 100px"
                        :src="currentRow.airImg.split(',')[0]"
                        :preview-src-list="currentRow.airImg.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="currentRow.airImg"
                        >共{{ currentRow.airImg.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div>
                    <div class="item-list">
                      温度计度数：{{ currentRow.thermometerNumber }} ℃
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">温度计照片：</div>
                      <el-image
                        v-if="currentRow.thermometerImg"
                        style="width: 100px; height: 100px"
                        :src="currentRow.thermometerImg.split(',')[0]"
                        :preview-src-list="currentRow.thermometerImg.split(',')"
                      >
                      </el-image>
                      <span
                        style="margin-left: 10px"
                        v-if="currentRow.thermometerImg"
                        >共{{
                          currentRow.thermometerImg.split(",").length
                        }}张</span
                      >
                    </div>
                    <div class="item-list">
                      提交人： {{ currentRow.createBy }}
                    </div>
                    <div class="item-list">
                      提交时间： {{ currentRow.createTime }}
                    </div>
                  </div>
                </div>
                <div
                  class="item-list"
                  style="flex: 1"
                  v-for="(item, index) in currentRow.workTestAirtightSubList"
                  :key="index"
                >
                  <div class="detil-code">实验后{{ index + 1 }}：</div>

                  <div v-if="currentRow.isAbsolute">
                    <div class="item-list">
                      绝压表度数：{{ item.jyNumberLater }} Pa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">绝压表照片：</div>
                      <el-image
                        v-if="item.jyImgLater"
                        style="width: 100px; height: 100px"
                        :src="item.jyImgLater.split(',')[0]"
                        :preview-src-list="item.jyImgLater.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="item.jyImgLater"
                        >共{{ item.jyImgLater.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div v-if="!currentRow.isAbsolute">
                    <div class="item-list">
                      压力表度数：{{ item.pressureNumberLater }} Mpa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">压力表照片：</div>
                      <el-image
                        v-if="item.pressureImgLater"
                        style="width: 100px; height: 100px"
                        :src="item.pressureImgLater.split(',')[0]"
                        :preview-src-list="item.pressureImgLater.split(',')"
                      >
                      </el-image>
                      <span
                        style="margin-left: 10px"
                        v-if="item.pressureImgLater"
                        >共{{ item.pressureImgLater.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div v-if="!currentRow.isAbsolute">
                    <div class="item-list">
                      气压表度数：{{ item.airNumberLater }} pa
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">气压表照片：</div>
                      <el-image
                        v-if="item.airImgLater"
                        style="width: 100px; height: 100px"
                        :src="item.airImgLater.split(',')[0]"
                        :preview-src-list="item.airImgLater.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="item.airImgLater"
                        >共{{ item.airImgLater.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div>
                    <div class="item-list">
                      温度计度数：{{ item.thermometerNumberLater }} ℃
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">温度计照片：</div>
                      <el-image
                        v-if="item.thermometerImgLater"
                        style="width: 100px; height: 100px"
                        :src="item.thermometerImgLater.split(',')[0]"
                        :preview-src-list="item.thermometerImgLater.split(',')"
                      >
                      </el-image>
                      <span
                        style="margin-left: 10px"
                        v-if="item.thermometerImgLater"
                        >共{{
                          item.thermometerImgLater.split(",").length
                        }}张</span
                      >
                    </div>
                  </div>
                  <div class="item-list">
                    修正后压力降：{{ item.pressureDropLater }}
                  </div>
                  <div class="item-list">
                    气密结果： {{ resulttype[item.result] }}
                    <span v-if="!item.isSubRectify && item.result == 3"
                      >不签发整改通知单</span
                    >
                    <span v-else-if="item.result == 3">签发整改通知单</span>
                  </div>
                  <div class="item-list">提交人： {{ item.createBy }}</div>
                  <div class="item-list">提交时间： {{ item.createTime }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="item in rectification" :key="item.id">
            <div style="display: flex" v-if="item.img">
              <div style="flex: 1">
                <p class="detil-code">整改单</p>
                <div style="padding: 10px">
                  <div class="item-list">整改原因： {{ item.cause }}</div>
                  <div class="item-list">
                    <div style="margin-bottom: 10px">不合格照片：</div>
                    <div>
                      <el-image
                        v-if="item.img"
                        style="width: 100px; height: 100px"
                        :src="item.img.split(',')[0]"
                        :preview-src-list="item.img.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="item.img"
                        >共{{ item.img.split(",").length }}张</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1" v-if="item.teams">
                <p class="detil-code">整改处理</p>
                <div style="padding: 10px">
                  <div class="item-list">
                    处理结果： {{ state[item.state] }}
                  </div>
                  <div class="item-list">
                    施工队： {{ item.teams.join("；") }}
                  </div>
                  <div class="item-list">操作人： {{ item.updateBy }}</div>
                  <div class="item-list">操作时间： {{ item.updateTime }}</div>
                </div>
              </div>
            </div>
            <div v-if="isShow(item.workInspectionSgdSubsetList)">
              <p class="detil-code">整改情况</p>
              <div style="padding: 10px">
                <div
                  v-for="list in item.workInspectionSgdSubsetList"
                  :key="list.id"
                  style="display: flex; flex-wrap: wrap"
                >
                  <div
                    style="margin-right: 20px; margin-bottom: 20px"
                    v-if="list.laterImg"
                  >
                    <div class="item-list">
                      整改施工队： {{ list.workTeamName }}
                    </div>
                    <div class="item-list">
                      <div style="margin-bottom: 10px">整改后情况：</div>
                      <div>
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="list.laterImg.split(',')[0]"
                          :preview-src-list="list.laterImg.split(',')"
                        >
                        </el-image>
                        <span style="margin-left: 10px" v-if="list.laterImg"
                          >共{{ list.laterImg.split(",").length }}张</span
                        >
                      </div>
                    </div>
                    <div class="item-list">
                      整改说明： {{ list.explain || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/apis/checkOuter/index.js";
export default {
  name: "lockout",
  data() {
    return {
      status2: {
        4: "试验完成",
        3: "试验完成",
        2: "实验中",
        1: "试验完成",
      },
      result: ["", "合格", "未出结果", "不合格"],
      state: {
        3: "已整改",
        2: "不整改",
        1: "进行整改",
        0: "未整改",
      },
      resulttype: {
        1: "合格",
        2: "实验中",
        3: "不合格",
        4: "未进行",
      },
      status: "12",
      activeName: 0,
      condition: true,
      project: {
        urlList: [
          "https://gas-dev.oss-cn-beijing.aliyuncs.com/gas_patrol_484857109813395456.png",
          "https://gas-dev.oss-cn-beijing.aliyuncs.com/gas_patrol_482242746828980224.png",
        ],
        tabs: [
          { name: "合格", value: "0", start: true },
          { name: "不合格", value: "1", start: false },
        ],
      },
      time: [],
      searchBox: {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      },
      total: 0,
      detilDialog: false,
      detilCalculate: false,
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 3,
        },
      ],
      tableData: [{}],
      positionList: [],
      currentRow: [],
      proLIst: {
        urlList: [
          {
            urlList: [
              "https://gas-dev.oss-cn-beijing.aliyuncs.com/gas_patrol_484857109813395456.png",
            ],
          },
        ],
      },
      rectification: null,
    };
  },
  props: {
    imgList: {
      type: Array,
    },
    acceptId: {
      type: String,
    },
  },
  mounted() {
    // let date = new Date();
    // let year = date.getFullYear();
    // let month = date.getMonth() + 1;
    // let day = date.getDate();
    // let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
    // let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
    // this.time.push(t1);
    // this.time.push(t2);
    // this.searchBox.startDate = t1;
    // this.searchBox.endDate = t2;
    this.searchBox.acceptId = this.acceptId;
    this.loadList(this.searchBox);
  },
  methods: {
    isShow(item) {
      let list = item.filter((el) => el.laterImg);
      return list.length;
    },
    timeChange(val) {
      if (val) {
        this.searchBox.startDate = val[0];
        this.searchBox.endDate = val[1];
      } else {
        this.searchBox.startDate = null;
        this.searchBox.endDate = null;
      }
    },
    // seeDetil(row) {
    //   this.currentRow = row;
    //   this.detilDialog = true;
    // },
    async seeDetil(row, type) {
      try {
        let { data } = await axiosApi.getIndoorRectify({
          id: row.id,
          source: 8,
        });
        this.currentRow = row;
        this.rectification = data;
        if (type == 1) {
          this.detilDialog = true;
        } else {
          this.detilCalculate = true;
        }
      } catch (error) {}
    },
    // generalDetil(row) {
    //   this.currentRow = row;
    //   this.detilCalculate = true;
    // },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    async loadList(obj) {
      try {
        let purgeData = {
          4: "实验终止",
          3: "不合格",
          2: "实验中",
          1: "合格",
        };
        let { data } = await axiosApi.getAirtight(obj);
        data.records.forEach((el) => {
          el.airtightTypeName = el.airtightType == 1 ? "普通气密" : "计算气密";
          //   el.result = purgeData[el.result];
        });
        this.tableData = data.records;
        this.total = data.total;
      } catch (error) {}
    },
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      };
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
      let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
      this.time = [t1, t2];
      this.searchBox.startDate = t1;
      this.searchBox.endDate = t2;
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 15px;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.item-list {
  margin-bottom: 15px;
}
</style>